import * as amplitude from '@amplitude/analytics-browser'

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || ''

export const initAmplitude = () => {
  if (!AMPLITUDE_API_KEY) {
    console.warn('⚠️ AMPLITUDE_API_KEY no está definida en el archivo .env')
    return
  }
  amplitude.init(AMPLITUDE_API_KEY)
}

export const logEvent = (eventName: string, eventProperties: Record<string, unknown> = {}) => {
  amplitude.track(eventName, eventProperties)
}
