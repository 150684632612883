import React, { ReactElement, useEffect } from 'react'

import GooglePlay from 'src/assets/images/GooglePlay.png'
import AppleStore from 'src/assets/images/AppleStore.png'
import Logo from 'src/assets/images/Trivu_logo.png'
import emotes from 'src/assets/images/emotes.png'

import { logEvent } from '../../api/analytics'
import styles from './Ads.module.scss'

export const Ads = (): ReactElement => {
  const handleCTAClick = (platform: string) => {
    logEvent('MARKETING_PAGE_CTA_CLICK', {
      platform,
      url: window.location.href,
    })
  }
  useEffect(() => {
    logEvent('MARKETING_PAGE_IMPRESSION', {
      url: window.location.href,
    })
  }, [])
  return (
    <div className={styles.ads}>
      <div className={styles.container}>
        <div className={styles.overlayContent}>
          <div className={styles.imageBox}>
            <img alt="Anuncio" className={styles.image} src={emotes} />
          </div>
          <h1 className={styles.title}>¡CAÍSTE!</h1>
          <p className={styles.subtitle}>
            Pero no te preocupes, desde ahora vas a viajar más barato
          </p>
          <p className={styles.subtitle}>Descargá nuestra app</p>
        </div>
      </div>

      <img src={Logo} alt="Logo de Trivu" width={60} />

      <div className={styles.downloads}>
        <a
          href="https://itunes.apple.com/ar/app/trivu-app/id1447382915?l=en&mt=8"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleCTAClick('Apple Store')}
        >
          <img src={AppleStore} alt="Apple Store" width="140" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.ar.trivuapp&hl=es"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleCTAClick('Google Play')}
        >
          <img src={GooglePlay} alt="Google Play" width="140" />
        </a>
      </div>
    </div>
  )
}
